import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import ChiroReview1 from "../images/chiro-review-1.png"
import ChiroReview2 from "../images/chiro-review-2.png"
import ChiroReview3 from "../images/chiro-review-3.png"
import ChiroReview4 from "../images/chiro-review-4.png"
import ChiroReview5 from "../images/chiro-review-5.jpg"
import ChiroReview6 from "../images/chiro-review-6.jpg"
import ChiroReview7 from "../images/chiro-review-7.png"
import ChiroReview8 from "../images/chiro-review-8.jpg"
import ChiroReview9 from "../images/chiro-review-9.jpg"
import ChiroReview10 from "../images/chiro-review-10.jpg"
import ChiroReview11 from "../images/chiro-review-11.png"
import ChiroReview12 from "../images/chiro-review-12.png"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import image1 from "../images/sport-testing/image1.png"
import image2 from "../images/sport-testing/image2.jpg"
import image3 from "../images/sport-testing/image3.jpg"
import {useCollapse} from "react-collapsed";
import OpenGraphLogo from "../images/open-graph-logo.png"

function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"text-lg font-bold p-1 flex justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }
            </h2>
            <section {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

function ReturnToSportTestingUpdated() {
    return(
        <div>
            <Helmet>
                <title>Return to Sport Testing | Movement Screening, Performance Metrics & Major Sports Injury Recovery</title>
                <meta name="description" content="Comprehensive return to sport testing and recovery programs, including ACL reconstruction, ankle sprains, Achilles injuries and youth athletes. Evidence-based movement screening, biomechanical analysis, and sport-specific benchmarking for confident return to play." />
                <meta property="og:title" content="Return to Sport Testing | Movement Screening, Performance Metrics & Major Sports Injury Recovery" />
                <meta property="og:description" content="Expert return to sport testing with advanced biomechanical analysis and injury-specific recovery protocols" />
                <meta property="og:image" content={OpenGraphLogo} />
                <meta name="keywords" content="return to sport testing, ACL reconstruction recovery, youth ACL recovery, ankle sprain rehabilitation, Achilles tear recovery, return to cutting sports, movement screening analysis, baseline performance metrics, multi-ligament knee injuries, chronic ankle instability, sport-specific benchmarking, recovery progression tracking, hamstring rehabilitation, return to pivoting sports, injury risk evaluation, biomechanical analysis, return-to-play clearance" />
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} header={"Return to Sport Testing & Movement Analysis"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                <p className={"max-w-5xl w-full lg:px-4 mx-auto italic"}>Does this sound like you?</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>You've been through physical therapy after your ACL reconstruction, ankle sprain, or other sports injury, but you're still unsure about returning to competition. You want to feel confident when you get back in the game.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Though your surgeon or physician has cleared you with basic tests, something still feels missing. Maybe you're a parent of a young athlete recovering from an ACL tear, concerned about their safe return to sports.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>You haven't been tested under conditions that truly match your sport's demands - the cutting, pivoting, and explosive movements that matter most. Whether you're coming back from an Achilles injury or dealing with chronic ankle issues, you need to know you're truly ready.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>You've done the basic exercises and stretches, but the path from rehabilitation to high-performance return isn't clear. You need a proven system to bridge this gap.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>It feels like you're spinning your wheels, unsure if you're truly ready to return. This is where our comprehensive Return to Sport Testing comes in.</p>

                    <div className={"lg:w-1/2 w-full h-fit pt-12 mx-auto"}>
                        <img className={"rounded"} src={image3} alt="Movement screening analysis"/>
                    </div>

                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8 pb-3"}>What traditional Return to Sport is Missing</h2>
                    <ul className={"lg:pt-4 pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Objective measures to track the progress of the physical qualities needed for your sport (not just range of motion, table tests and bodyweight exercises).</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Physical therapists or clinicians with strength and conditioning backgrounds that understand the demands of your sport.</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— A comprehensive and collaborative plan that is designed to get you stronger and more confident than pre-injury.&nbsp;</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— The level of challenge your body needs that matches or exceeds your sport to vaccinate your body from re-injury as best as possible.</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Enough objective data relative to your sport to have a clear understanding of what needs to be worked on to get back to your sport CONFIDENTLY without the guesswork.</li>
                    </ul>

                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8 pb-3"}>Sport-Specific Performance Assessment</h2>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Our testing incorporates sport-specific benchmarking to ensure you're ready for the demands of your activity. We evaluate:</p>
                    <ul className={"lg:pt-4 pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Speed and agility assessment using advanced timing systems</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Plyometric ability and power development</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Sport-specific movement patterns and techniques</li>
                        <li className={"pt-2 max-w-5xl w-full lg:pl-12 pl-6 lg:pr-2 mx-auto"}>— Recovery progression tracking through each phase</li>
                    </ul>

                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8"}>Dynamometer</h2>
                    <div className={"flex lg:flex-wrap flex-wrap-reverse flex-row max-w-5xl w-full"}>
                        <div className={"lg:w-2/3 w-full lg:p-8 p-2"}>
                            <p className={"pt-4  max-w-5xl w-full lg:px-4 mx-auto"}>This tells us how much force one can produce and how quickly one can produce it. This is important for a few reasons:</p>
                            <ul className={"lg:pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>1. Getting a baseline of strength prior to surgery</li>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>2. Objectively measuring amount and speed of force </li>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>3. Test strength of individual muscle groups</li>
                            </ul>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>The earlier you test the strength of the non-injured leg the better. This baseline testing should be done before any weakness happens due to lessened or complete inactivity. This then serves as the target for the surgically repaired knee. This is what the pros do, so why not you? This is something we offer free of charge for an athlete waiting for surgery. We are extremely passionate about raising the standards to decrease risks of reinjury so contact us if you or someone you know is interested.</p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Force is the name of the game when it comes to sports. Essentially all team sports are about attacking or evading space as well as in some cases throwing/catching an object. What do all of these have in common? The more force one can create the faster the athlete can achieve these tasks. </p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>The second reason is we can test individual muscle groups which can detect weaknesses that go unseen in traditional return to play testing like hop tests. Athletes are incredible compensators so being able to test the strength of individual muscles (as well as global tests) is crucial for return to play criteria. </p>
                        </div>
                        <div className={"lg:w-1/3 w-full h-fit pt-12"}>
                            <img className={"rounded"} src={image1} alt="Dynamometer testing"/>
                        </div>
                    </div>

                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8"}>Force Plate</h2>
                    <div className={"flex flex-wrap flex-row max-w-5xl w-full "}>
                        <div className={"lg:w-1/3 w-full h-fit pt-12"}>
<img className={"rounded"} src={image2} alt="Force plate analysis"/>
                        </div>
                        <div className={"lg:w-2/3 w-full lg:p-8 p-2"}>
                            <p className={"pt-4  max-w-5xl w-full lg:px-4 mx-auto"}>The force plates are an incredible tool that have now become more available for facilities like us. This technology gives us the ability to have a professional level sports medicine lab's worth of data guide us to make smart training decisions from how intense sessions should be to exactly how we want to load the body to get the most out of training. Here are the main reasons we use the force plates.</p>
                            <ul className={"lg:pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>1. Readiness and fatigue monitoring</li>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>2. Comparing one leg to another </li>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>3. How high and fast an athlete can get off the ground</li>
                                <li className={"pt-2 max-w-5xl w-full pl-6 lg:pr-2 mx-auto"}>4. Monitor landing mechanics and how an athlete absorbs forces</li>
                            </ul>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>One of the most important aspects of training or rehab is knowing when to go hard and when to take it easy. A quick look at how an athlete is jumping along with a conversation guides the coach or practitioner on how strenuous a session should be (this is true individualization and vital to speed the rehab process). </p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Being able to objectively compare the non-surgical and surgical legs (or arm) is another key component to the rehab process to decrease risk of reinjury (especially in the later stages of rehab). The force plates not only look at how high an athlete can jump, but it can gather the data that tells us how that athlete pushes off the ground and how they absorb the forces when landing. </p>
                            <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>This detailed data curtails the athlete's individualized program and is why every one of our practitioners has extensive knowledge in both rehab and strength and conditioning.</p>
                        </div>
                    </div>


                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8 pb-6"}>Major Ligament & Tendon Injuries</h2>
                    
                    <h3 className={"text-2xl font-bold pb-2 text-center"}>ACL Reconstruction Recovery</h3>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>ACL reconstruction recovery requires more than just following standard protocols. Our comprehensive approach begins before surgery and continues through your full return to sport. Using our advanced testing equipment, we establish baseline measurements of your unaffected leg, giving us precise targets for your recovery. This data-driven approach ensures you're not just meeting general milestones, but achieving the specific strength and stability levels you need for your sport.</p>

                    <h4 className={"text-xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto  text-center"}>Youth Athletes and ACL Recovery</h4>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>Young athletes face unique challenges during ACL recovery. With growing bodies and developing movement patterns, they need specialized attention to ensure proper healing and prevent future injuries. Our youth ACL program considers growth plate development, long-term athletic potential, and sport-specific demands. We work closely with parents, coaches, and medical professionals to create a safe, progressive return to sport that builds both physical capability and confidence.</p>

                    <h4 className={"text-xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto  text-center"}>Return to Cutting Sports</h4>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>Sports that demand quick direction changes and pivoting movements require special attention after ACL reconstruction. Our return-to-cutting program progressively challenges your knee through sport-specific movements. Using force plate analysis and advanced movement screening, we assess your readiness for each new challenge. From basic agility drills to high-intensity game simulations, we ensure you're building the strength, control, and confidence needed for safe return to competition.</p>

                    <h3 className={"text-2xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto pt-6  text-center"}>Achilles Recovery Journey</h3>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>Recovering from an Achilles tear or repair requires careful progression of load and movement. Our specialized Achilles recovery program uses precise force measurements to guide your progression. We begin with controlled loading exercises and advance through a careful sequence of strength development. Using our force plates, we can measure exactly how much load you're putting through the healing tendon, ensuring optimal healing stimulus without overload.</p>

                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>As you progress, we introduce dynamic movements and sport-specific training. Our advanced testing equipment helps us monitor your power development, ensuring you regain the explosive strength needed for jumping, sprinting, and quick movements. We'll guide you through progressive plyometric training and speed development, always monitoring tissue response and adapting the program to optimize your recovery.</p>

                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8 pb-6"}>Common Athletic Injuries</h2>

                    <h3 className={"text-2xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto  text-center"}>Ankle Sprain Recovery</h3>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>Ankle sprains are often undertreated, leading to chronic instability and repeated injuries. Our comprehensive ankle recovery program goes beyond basic rehabilitation exercises. Using our advanced testing equipment, we assess not just range of motion and strength, but also movement quality, balance control, and power development. This detailed analysis helps us identify the specific deficits that need addressing for complete recovery.</p>

                    <h4 className={"text-xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto text-center"}>Chronic Ankle Instability</h4>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>For athletes dealing with recurring ankle issues, we provide specialized testing and treatment. Our force plate analysis can detect subtle stability deficits that traditional tests miss. We use this information to create targeted programs that address both the mechanical and neuromuscular aspects of ankle stability. Through progressive challenges and sport-specific training, we help you build the robust ankle function needed for long-term stability.</p>

                    <h3 className={"text-2xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto pt-6 text-center"}>Sport-Specific Return Protocols</h3>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>Different sports place unique demands on your body during recovery. Court sports require quick directional changes and reactive movements. Field sports demand sustained power output and acceleration capability. Our sport-specific protocols account for these varying demands, ensuring your recovery program prepares you for the exact challenges you'll face in your sport.</p>

                    <h3 className={"text-2xl font-bold pb-2 max-w-5xl w-full lg:px-4 mx-auto pt-6 text-center"}>Preventing Re-Injury</h3>
                    <p className={"pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>A successful return to sport means more than just getting back in the game - it means staying there. Our comprehensive testing protocols help identify any remaining risk factors for re-injury. We'll work with you to address these factors through targeted training, ensuring you're not just recovered, but more resilient than before your injury.</p>


                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8 pb-3"}>Comprehensive Movement Screening & Analysis</h2>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Our return-to-sport process begins with a thorough movement screening analysis and biomechanical assessment. We establish baseline performance metrics across multiple domains including strength, speed, agility, and power output. This comprehensive approach helps us identify potential injury risks and create a targeted plan for your return to play.</p>


                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-8"}>Advanced Speed Training & Power Development</h2>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Beyond basic testing, we incorporate specialized speed training and plyometric programs to enhance your performance. Whether you're recovering from an ACL reconstruction, ankle sprain, or other sports injury, our return-to-play clearance process ensures you're not just recovered, but stronger and more resilient than before.</p>

                    <h2 className={"max-w-5xl w-full mx-auto font-bold text-4xl text-center pt-5 pb-5"}>Strength and Conditioning</h2>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>Developing the general physical qualities like strength, mobility, speed, power, work capacity (repeatability) is what gives an athlete the best chance to have a long and healthy career whether coming back from injury or completely healthy. </p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>We are extremely passionate about this and it is why you will not see run of the mill exercises that everyone gets, a sheet of random exercises or any other low level care. Because all of our practitioners are both knowledgeable and passionate about strength and conditioning, everyone we work with gets an individualized program to get them feeling the most resilient they have felt in their career.</p>
                    <p className={"pt-4 max-w-5xl w-full lg:px-4 mx-auto"}>It is our mission to give people the tools they need to not only get back in the game, but to become more resilient and confident than ever before. Let's get you back on track and feel what it is like to rehab like the pros. </p>

                    <div className={"pt-8 pb-4 max-w-5xl w-full lg:px-4 mx-auto"}>
                        <h3 className={"font-bold text-2xl pb-3"}>Your Path to Return-to-Play Clearance</h3>
                        <p>Our comprehensive return to sport process includes:</p>
                        <ul className={"lg:pt-4 pb-4"}>
                            <li className={"pt-2 pl-6"}>— Regular biomechanical analysis to track progress</li>
                            <li className={"pt-2 pl-6"}>— Advanced injury risk evaluation protocols</li>
                            <li className={"pt-2 pl-6"}>— Progressive speed and agility training</li>
                            <li className={"pt-2 pl-6"}>— Continuous performance metric tracking</li>
                        </ul>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Let's Work Together!
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default ReturnToSportTestingUpdated